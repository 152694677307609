var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"script-history-container"},[_c('div',{staticClass:"module-header",staticStyle:{"margin-bottom":"24px"}},[_vm._v("执行历史")]),_c('reload-button',{staticStyle:{"position":"absolute","right":"24px","top":"32px","z-index":"999"},on:{"click":function($event){return _vm.fetch()}}}),_c('a-table',{staticClass:"custom-table scroll-hidden-table",staticStyle:{"min-height":"350px"},attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"pagination":false,"row-key":"created_at","scroll":{ x: _vm.scrollX },"total":_vm.total,"loading":_vm.loading,"components":_vm.components},scopedSlots:_vm._u([{key:"name",fn:function(text){return [_c('a-tooltip',{attrs:{"title":text}},[_vm._v(_vm._s(text))])]}},{key:"createdBy",fn:function(text){return [(text)?_c('a-tooltip',{attrs:{"title":text.nickname}},[_vm._v(_vm._s(text.nickname))]):_c('span',[_vm._v("-")])]}},{key:"finished",fn:function(text){return [_c('span',{style:({
          display: 'inline-block',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          marginRight: '8px',
          background: text ? '#5FC367' : '#FF4D4F'
        })}),_vm._v(" "+_vm._s(text ? '已完成' : '未完成'))]}},{key:"script",fn:function(text){return [(text)?_c('a-tooltip',{attrs:{"title":text.name}},[_vm._v(_vm._s(text.name))]):_c('span',[_vm._v("-")])]}},{key:"timeout",fn:function(text){return [_vm._v(_vm._s(text)+" ")]}},{key:"sources",fn:function(text){return [_vm._v(_vm._s(text.length))]}},{key:"operation",fn:function(text, record){return [_c('a',{directives:[{name:"permission",rawName:"v-permission",value:({action: 'ops.script_task.view', effect: 'disabled'}),expression:"{action: 'ops.script_task.view', effect: 'disabled'}"}],on:{"click":function($event){return _vm.$router.push({name: 'ScriptExecuteDetail', params: {id: record.id}})}}},[_vm._v("查看详情")])]}}])}),_c('div',{staticStyle:{"position":"absolute","bottom":"16px","right":"16px"}},[_c('a-pagination',{attrs:{"current":_vm.fetchParams.page,"pageSize":_vm.fetchParams.page_size,"show-size-changer":true,"show-total":t => `共 ${t} 条`,"total":_vm.total},on:{"change":page => {
          _vm.fetchParams.page = page
          _vm.fetch()
        },"showSizeChange":(_, pageSize) => {
          _vm.fetchParams.page = 1
          _vm.fetchParams.page_size = pageSize
          _vm.fetch()
        }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }