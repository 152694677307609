<template>
  <div class="script-history-container">
    <div class="module-header" style="margin-bottom: 24px">执行历史</div>

    <reload-button
      @click="fetch()"
      style="position: absolute; right: 24px; top: 32px; z-index: 999"
    ></reload-button>
    <a-table
      class="custom-table scroll-hidden-table"
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      row-key="created_at"
      :scroll="{ x: scrollX }"
      :total="total"
      :loading="loading"
      :components="components"
      style="min-height: 350px;"
    >
      <template slot="name" slot-scope="text">
        <a-tooltip :title="text">{{ text }}</a-tooltip>
      </template>
      <template slot="createdBy" slot-scope="text">
        <a-tooltip :title="text.nickname" v-if="text">{{ text.nickname }}</a-tooltip>
        <span v-else>-</span>
      </template>
      <template slot="finished" slot-scope="text">
        <span
          :style="{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            marginRight: '8px',
            background: text ? '#5FC367' : '#FF4D4F'
          }"
        ></span>
        {{ text ? '已完成' : '未完成' }}</template>
      <template slot="script" slot-scope="text">
        <a-tooltip :title="text.name" v-if="text">{{ text.name }}</a-tooltip>
        <span v-else>-</span>
      </template>
       <template slot="timeout" slot-scope="text">{{ text }} </template>
      <template slot="sources" slot-scope="text">{{ text.length }}</template>
      <template slot="operation" slot-scope="text, record">
        <a v-permission="{action: 'ops.script_task.view', effect: 'disabled'}" @click="$router.push({name: 'ScriptExecuteDetail', params: {id: record.id}})">查看详情</a>
      </template>
    </a-table>

    <div style="position: absolute; bottom: 16px; right: 16px;">
      <a-pagination
        :current="fetchParams.page"
        :pageSize="fetchParams.page_size"
        :show-size-changer="true"
        :show-total="t => `共 ${t} 条`"
        :total="total"
        @change="
          page => {
            fetchParams.page = page
            fetch()
          }
        "
        @showSizeChange="
          (_, pageSize) => {
            fetchParams.page = 1
            fetchParams.page_size = pageSize
            fetch()
          }
        "
      ></a-pagination>
    </div>
  </div>
</template>

<script>
import { getScriptTaskList } from '@/api/script-task'
import ReloadButton from '@/components/button/ReloadButton.vue'

export default {
  name: 'HistoryList',
  components: {
    ReloadButton
  },
  data () {
    return {
      loading: false,
      columns: [
        {
          dataIndex: 'name',
          title: '任务名称',
          width: 180,
          ellipsis: true,
          // fixed: 'left',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'finished',
          title: '执行状态',
          width: 100,
          scopedSlots: {
            customRender: 'finished'
          }
        },
        {
          dataIndex: 'timeout',
          title: '超时时间（秒）',
          width: 160,
          align: 'center',
          scopedSlots: {
            customRender: 'timeout'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'script',
          title: '脚本名称',
          ellipsis: true,
          width: 150,
          scopedSlots: {
            customRender: 'script'
          }
        },
        {
          dataIndex: 'created_by',
          title: '执行用户',
          width: 160,
          scopedSlots: {
            customRender: 'createdBy'
          }
        },
        {
          dataIndex: 'sources',
          title: '主机数量',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'sources'
          }
        },
        {
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 120,
          scopedSlots: {
            customRender: 'operation'
          }
        }
      ],
      dataSource: [],
      total: 0,
      fetchParams: {
        page: 1,
        page_size: 20
      },
      components: {
        header: {}
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getScriptTaskList(this.fetchParams).then(res => {
        this.dataSource = res.data.data
        this.total = res.data.total
      }).finally(() => { this.loading = false })
    },
    refresh () {
      this.fetchParams = {
        page: 1,
        page_size: 20
      }
      this.fetch()
    }
  }
}
</script>

<style lang="less">
.script-history-container{
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  padding: 16px 16px 72px 16px;
}
</style>
